<template>
  <ag-grid-vue
    :statusBar="statusBar"
    :pivotPanelShow="pivotPanelShow"
    :rowGroupPanelShow="rowGroupPanelShow"
    v-bind="$attrs"
    v-on="$listeners"
    multiSortKey="ctrl"
    :animateRows="true"
    :gridOptions="gridOptions"
    style="width: 100%; height: calc(100% - 38px);"
    :enableRangeSelection="true"
    :class="`${agGridTheme}`"
    :localeText="agGridLocaleText"
    :defaultColDef="defaultColDef"
  ></ag-grid-vue>
</template>

<script>
// eslint-disable-next-line
import 'ag-grid-community/dist/styles/ag-grid.css';
// eslint-disable-next-line
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// eslint-disable-next-line
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
// eslint-disable-next-line
import { AgGridVue } from 'ag-grid-vue';

export default {
  name: 'AgGrid',
  components: {
    AgGridVue,
  },
  props: {
    pivotPanelShow: {
      type: String,
      default: 'always',
    },
    rowGroupPanelShow: {
      type: String,
      default: 'always',
    },
    showStatusBar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sideBar: null,
      statusBar: null,
      gridApi: null,
      gridOptions: null,
      gridColumnApi: null,
      defaultColDef: null,
    };
  },
  computed: {
    agGridLocaleText() {
      return this.$store.getters['helper/agGridLocaleText'];
    },
    agGridTheme() {
      return this.$store.getters['helper/agGridTheme'];
    },
  },
  created() {
    this.gridOptions = {
      groupDefaultExpanded: -1,
    };
    this.defaultColDef = {
      filter: true,
      sortable: true,
      resizable: true,
      enableValue: true,
      enablePivot: true,
      enableRowGroup: true,
      floatingFilter: true,
    };
    if (this.showStatusBar) {
      this.statusBar = {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left',
          },
          { statusPanel: 'agSelectedRowCountComponent' },
        ],
      };
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  watch: {
    '$attrs.columnDefs': {
      deep: true,
      handler(val) {
        if (val.length) {
          this.autoFit();
        }
      },
    },
  },
  methods: {
    autoFit() {
      this.$nextTick(() => {
        this.gridOptions.columnApi.autoSizeAllColumns(false);
      });
    },
  },
};
</script>
<style>
.ag-watermark, .ag-watermark:before {
  display: none !important;
}
</style>
