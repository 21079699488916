<template>
  <v-form ref="form" v-model="isValid" @submit.prevent="submitfilter">
    <v-select
      dense
      outlined
      class="mt-1"
      label="Report"
      v-model="selectedReport"
      :items="appreport"
      item-value="value"
      item-text="description"
      :loading="fetching"
      :rules="[v => !!v || 'Report is required']"
    ></v-select>
    <v-text-field
      label="Date from"
      dense
      v-model="dateFrom"
      type="date"
      :disabled="fetching"
      outlined
      :rules="dateFromRules"
    ></v-text-field>
    <v-text-field
      label="Date to"
      dense
      v-model="dateTo"
      type="date"
      :disabled="fetching"
      outlined
      :rules="dateToRules"
    ></v-text-field>
    <v-select
      dense
      outlined
      class="mt-1"
      label="Line"
      v-model="selectedLine"
      :items="lines"
      item-value="value"
      item-text="description"
      :loading="fetching"
      :rules="[v => !!v || 'Line is required']"
    ></v-select>
    <v-select
      dense
      outlined
      class="mt-1"
      label="Shift"
      v-model="selectedShift"
      :items="shiftList"
      :loading="fetching"
      :rules="[v => !!v || 'Shift is required']"
    ></v-select>
     <v-btn
      block
      color="primary"
      type="submit"
      :loading="fetching || fetchingdata"
      :disabled="!isValid || fetchingdata"
    >
      Apply filters
    </v-btn>
  </v-form>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  props: {
    fetchingdata: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValid: false,
      selectedLine: '',
      selectedShift: '',
      dateFrom: '',
      dateTo: '',
      selectedReport: '',
      appreport: [],
      loading: false,
    };
  },
  computed: {
    ...mapState('dataAnalyzer', ['machines']),
    ...mapGetters('dataAnalyzer', ['lines', 'shiftList']),
    fetching() {
      return this.loading;
    },
    dateFromRules() {
      return [
        (v) => !!v || 'Date is required',
        () => this.isValidDate() || `Should be equal or before ${this.dateTo}`,
      ];
    },
    dateToRules() {
      return [
        (v) => !!v || 'Date is required',
        () => this.isValidDate() || `Should be equal or after ${this.dateFrom}`,
      ];
    },
  },
  async mounted() {
    this.loading = true;
    await this.getMachines();
    await this.getReportList();
    await this.getShifts();
    this.loading = false;
  },
  methods: {
    ...mapActions('dataAnalyzer', [
      'getMachines', 'getRecords', 'getShifts',
    ]),
    submitfilter() {
      const payload = {
        reportName: this.selectedReport,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        line: this.selectedLine,
        shift: this.selectedShift,
      };
      this.$emit('fetchdata', payload);
    },
    async getReportList() {
      const details = {
        elementName: 'analyzerreports',
        query: '',
      };
      const result = await this.getRecords(details);
      this.appreport = result;
    },
    isValidDate() {
      let result = true;
      if (this.dateTo && this.dateFrom) {
        const start = new Date(this.dateFrom).getTime();
        const end = new Date(this.dateTo).getTime();
        result = end >= start;
      }
      return result;
    },
  },
};
</script>
