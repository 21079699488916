<template>
  <v-container fluid style="height:100%" id="app-container">
    <portal to="app-header">
      {{ $t('modules.dataAnalyzer') }}
    </portal>
    <div class="d-flex justify-space-between">
      <span class="title">Filters</span>
      <v-btn
        small
        color="primary"
        class="text-none ml-4"
        @click="$refs.filters.submitfilter()"
        :disabled="columnDefs.length < 1"
      >
        Refresh
      </v-btn>
    </div>
    <v-row style="height:100%">
      <v-col cols="12" sm="4" lg="3" xl="2">
        <FilterSidebar ref="filters" @fetchdata="fetchReport" :fetchingdata="loading"/>
      </v-col>
      <v-col cols="12" sm="8" lg="9" xl="10">
        <ag-grid
          ref="grid"
          :rowData="rowData"
          :columnDefs="columnDefs"
          :rowGroupPanelShow="'false'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import AgGrid from '@shopworx/ui-components/grid/AgGrid.vue';
import FilterSidebar from '../components/FilterSidebar.vue';

export default {
  name: 'DataAnalyzer',
  components: {
    AgGrid,
    FilterSidebar,
  },
  data() {
    return {
      loading: false,
      rowData: [],
      columnDefs: [],
    };
  },
  methods: {
    ...mapActions('dataAnalyzer', [
      'getElements', 'getReport',
    ]),
    async fetchReport(filter) {
      this.loading = true;
      const { reportName } = filter;
      const payload = {
        end: this.dateFormat(filter.dateTo),
        start: this.dateFormat(filter.dateFrom),
        lineVal: filter.line,
        shiftVal: filter.shift,
      };
      const report = await this.getReport({ payload, reportName });
      this.columnDefs = report.cols.map((col) => ({
        headerName: col.description,
        field: col.name,
        colId: col.name,
      }));
      this.rowData = report.reportData;
      this.loading = false;
    },
    dateFormat(val) {
      const date = new Date(val);
      const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);

      return parseInt(`${year}${month}${day}`, 10);
    },
    fetchRecords(e) {
      this.$refs.grid[0].setRowData(e);
    },
    exportRecords(e) {
      this.dialog = true;
      this.exportPayload = e;
    },
  },
  async created() {
    this.loading = true;
    this.loading = false;
  },
};
</script>

<style>
.v-window__container {
  height: 100%;
}
</style>
